//base url
export const LOGIN_PAGE = 'https://dev-home.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://dev-home.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://dev-api.phillipsoutsourcing.net';
export const DOCUMENT_BASE_URL = 'https://s3.us-east-1.amazonaws.com/dev-api.phillipsoutsourcing.net';

//statistics
export const PAYROLL_DASHBOARD_STATISTICS = '/esm-dashboard-statistics';
export const ESM_DASHBOARD_STATISTICS = '/esm-dashboard-statistics';


//banks
export const BANKS = '/banks';
export const RESOLVE_BANK_ACCOUNT = '/api/resolve';
export const PFAS = '/pfa-names';


//general urls
export const COUNTRIES_URL = '/countries';

//users
export const USERS_URL = '/users';
export const GET_USER_AUTHORIZATION = '/get-user-authorization';
export const START_ONBOARDING = '/start-onboarding';

//company
export const EMPLOYEE_COMPANY_URL = '/get-employee-company';
export const COMPANY_FILE_URL = '/company-files';
export const COMPANIES_URL = '/companies';
export const GET_REPRESENTATIVE_CLIENTS = '/representative-clients';
//employees
export const SEARCH_CITY = '/search-cities';
export const EMPLOYEE_URL = '/employees';
export const SEARCH_EMPLOYEE_LISTS = '/search-employee-list';
export const EMPLOYEE_VOTES = '/employee-votes';
export const SEARCH_LOCAL_GOVT = '/search-local-governments';
export const EMPLOYEE_EXITS = '/employee-exits';
export const EXIT_REQUEST = '/initiate-exit-requests';
export const UPDATE_EXIT_STAGE = '/update-exit-stage';
export const CONTINUE_EXIT_REQUEST = '/continue-exit-requests';
export const EMPLOYEE_QUERIES = '/employee-queries';
export const GET_EXIT_DOWNLOADABLES = '/get-exit-downloadables';
export const SAVE_QUERY_RESPONSE = '/save-query-response';
export const TRAINING_SCHEDULES = '/training-schedules';
export const GET_CANDIDATE_TASKS = '/get-candidate-tasks';
export const ACTIVATE_NEXT_TASK = '/activate-next-task';
export const SUBMIT_ONBOARDING_RECORD = '/submit-onboarding-record';
export const MARK_TASK_COMPLETED = '/mark-task-completed';
export const SAVE_EMPLOYEE_PROFILE = '/save-employee-profile';
export const GET_EMPLOYEE_PROFILE = '/get-employee-profile';
export const EMPLOYEE_PROFILE = '/employees';
export const WORK_EXPERIENCES = '/work-experiences';
export const PROF_QUALIFICATION = '/qualifications';
export const NEXT_KINS = '/next-kins';
export const GUARANTORS = '/guarantors';
export const BENEFICIARIES = '/beneficiaries';
export const DEPENDENTS = '/dependents';
export const EMPLOYEE_FILES = '/employee-files';
export const OPTIONAL_FILES = '/optional-files';
export const EDU_RECORDS = '/educational-records';
export const SEARCH_UNIVERSITY = '/search-universities';
export const SEARCH_COURSES = '/search-courses';
export const SEARCH_DEGREES = '/search-degrees';
export const DEGREE_CLASSES = '/degree-classes';
export const RELATIONSHIPS = '/relationships';
export const ONBOARDING_DOCUMENTS = '/onboarding-documents';
export const GET_TASKS_DOCUMENTS = '/get-tasks-documents';
export const GET_DOWNLOADABLE_DOCUMENTS = '/get-downloadable-documents';
export const SEARCH_PERSONAL_TITLES = '/search-personal-titles';
export const GENOTYPES = '/genotypes';
export const BLOOD_GROUPS = '/blood-groups';

export const TOGGLE_CHECK_IN = '/toggle-check-in';
export const GET_EMPLOYEE_TIME = '/get-employee-time';
export const SEARCH_COMPANY_EMPLOYEE = '/search-company-employee';
export const GET_EMPLOYEE_ATTENDANCE = '/get-employee-attendance';
export const SEARCH_SKILLS = '/search-skills';


export const GET_TODAY_CELEBRANTS = '/get-today-celebrants';
export const GET_CELEBRANTS = '/coming-celebrants';
export const GET_NEW_HIRES = '/get-new-hires';
export const GET_DEPARTMENT_MEMBERS = '/get-department-members';
export const ANNOUNCEMENTS_URL = '/announcements';
export const ANNOUNCEMENT_COMMENTS_URL = '/announcement-comments';
export const COMPANY_ANNOUNCEMENTS_URL = '/get-company-announcements';
export const SEARCH_ANNOUNCEMENTS = '/search-announcements';
export const SEARCH_QUERIES = '/search-queries';
export const ADD_ANNOUNCEMENT_REACTION = '/add-reaction';


//leave
export const LEAVE_REQUEST = '/request-new-leave';
export const LEAVE_RESUMPTION_REQUEST = '/leave-resumption-request';
export const CANCEL_LEAVE_REQUESTS = '/cancel-leave-requests';
export const GET_APPLICABLE_LEAVES = '/get-applicable-leaves';
export const LEAVE_APPLICATIONS = '/leave-applications';

//payroll

export const MANAGEMENT_PAYROLLS = '/management-payrolls';
export const SEND_APPROVAL_NOTIFICATION = '/send-payroll-approval';

export const GET_GENERAL_JOURNAL_REPORTS = '/get-general-journal-reports';
export const GET_GENERAL_LEDGER_REPORTS = '/get-general-ledger-reports';

export const GET_PAYROLL_JOURNAL_ENTRIES = '/get-payroll-journal-entries';

export const POST_JOURNAL_ENTRIES = '/post-journal-entries';
export const GET_PAYROLL_APPROVAL_STATUS = '/get-payroll-approval-status';
export const GET_SELECTED_DISAPPROVAL = '/get-selected-disapproval';
export const UPDATE_APPROVALS = '/update-approvals';
export const PAYROLL_APPROVAL_INFO = '/payroll-approval-info';

export const SEND_DISAPPROVAL_NOTIFICATION = '/send-disapproval-notification';
export const TOGGLE_PAYROLL_APPROVAL = '/toggle-payroll-approval';
//exporying
export const EXPORT_JOURNAL = '/export-journal';
export const EXPORT_PAYROLL_REGISTER = '/export-register';
export const GET_PAYROLL_REGISTER = '/get-payroll-register';
export const GET_PAYROLL_REGISTER_FOR_EXPORT = '/get-payroll-register-export';

//management

export const GET_MANAGEMENT_STATISTICS = '/get-management-statistics';
export const GET_NET_HISTORY = '/get-net-history';

